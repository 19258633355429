import React, { useState, useEffect, useRef } from 'react'
import video from 'assets/videos/home.mp4'

const texts = ['6 Estados', '50 Municípios', 'De Palotina - PR', 'Para o Brasil', '#PADRAOICTUS']

function Home() {
  const [index, setIndex] = useState(0)
  const h1Ref = useRef(null)

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length)
    }, 4000)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (h1Ref.current) {
      h1Ref.current.textContent = texts[index]
      h1Ref.current.classList.remove('animate-slideFade')
      void h1Ref.current.offsetWidth
      h1Ref.current.classList.add('animate-slideFade')
    }
  }, [index])

  return (
    <section className='relative min-h-screen'>
      <video className='absolute left-0 top-0 h-full w-full object-cover' src={video} autoPlay loop muted playsInline loading='lazy' nClick={(e) => e.preventDefault()} />
      <div className='relative z-10 flex min-h-screen items-center justify-center'>
        <h1 ref={h1Ref} className='text-[42px] font-bold uppercase text-white md:text-9xl'></h1>
      </div>
    </section>
  )
}

export default Home
